import Vue from "vue";
import router from "./router";
import app from "./App";
import VueRouter from "vue-router";
import vuetify from "./plugins/vuetify";
import 'vuetify/dist/vuetify.min.css'


//Vue.use(ElementUI)

Vue.use(VueRouter);



new Vue({
  render: (h) => h(app),
  router,
  data() {
    return {
      uid: "",
      opcode: "",
      opname: "",
      cono: "",
      drawer: false,
      seclev: 0,
      scrnname: "",
      businessname: "",
      navmenu: 0,
      wpmenu: 0,
      emailaddr: "",
      mm: false,
      wp: false,
      common: "https://strategy.pljnet.com/common",
      classes: "https://strategy.pljnet.com/classes",
      PDF: "https://strategy.pljnet.com/PDFDocuments",
      rootdir: "https://strategy.pljnet.com/",
    };
  },

  vuetify,

  methods: {
    setUid(uid) {
      this.uid = uid;
    },
    setSeclev(seclev) {
      this.seclev = seclev;
    },
    setOpcode(opcode) {
      this.opcode = opcode;
    },
    currencyFormat(num) {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
    intFormat(num) {
      return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },
  },
}).$mount("#app");
