import Vue from "vue";
import router from "vue-router";
Vue.use(router);

export default new router({
  routes: [
    {
      path: "/",
      name: "logon",
      props: true,
      component: () => import("../components/logon.vue"),
    },
    {
      path: "/dash",
      name: "dash",
      props: true,
      component: () => import("../components/dash.vue"),
    },
    {
      path: "/mission",
      name: "mission",
      props: true,
      component: () => import("../components/missionvision.vue"),
    },
    {
      path: "/marketing",
      name: "marketing",
      props: true,
      component: () => import("../components/marketing.vue"),
    },

    {
      path: "/branding",
      name: "branding",
      props: true,
      component: () => import("../components/branding.vue"),
    },
    {
      path: "/endgame",
      name: "endgame",
      props: true,
      component: () => import("../components/endgame.vue"),
    },
    {
      path: "/12month",
      name: "12month",
      props: true,
      component: () => import("../components/12month.vue"),
    },
    {
      path: "/3month",
      name: "3month",
      props: true,
      component: () => import("../components/3month.vue"),
    },
    {
      path: "/todo",
      name: "todo",
      props: true,
      component: () => import("../components/todo.vue"),
    },
    {
      path: "/busrep",
      name: "busrep",
      props: true,
      component: () => import("../components/strategyreport.vue"),
    },
    {
      path: "/swot",
      name: "swot",
      props: true,
      component: () => import("../components/swot.vue"),
    },
    {
      path: "/5yp",
      name: "5yp",
      props: true,
      component: () => import("../components/5yp.vue"),
    },
    {
      path: "*",
      props: true,
      component: () => import("../components/logon.vue"),
    },
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
  },
});
