<template>
  <v-container fluid>
    <v-app-bar
      dense
      app
      scroll-target="#sheet1"
      height="80"
      class="grey white--text"
    >
      <v-row class="mx-3" justify="end" dense>
        <v-col cols="12">
          <v-row dense>
            <v-col
              cols="6"
              class="title white--text"
              v-if="$root.businessname != ''"
            >
              Business Building Blocks  - {{ $root.businessname }}
            </v-col>
            <v-col
              cols="6"
              class="title white--text"
              v-if="$root.businessname == ''"
            >
            Business Building Blocks
            </v-col>
            <v-spacer />
            <v-col cols="4" class="text-right" justify="end">
              <v-btn color="blue-grey" v-if="$root.mm || $root.wp" small @click="repkey++" class="mx-1 white--text">
                Refresh
                <v-icon right dark>mdi-refresh</v-icon>
              </v-btn>
              <v-btn color="blue-grey" small class="mx-1 white--text"
              @click="$root.wp = true; $root.mm = false; handleSelect('module1')" v-if="$root.mm" >
                Working Papers
                <v-icon right dark>mdi-text-box-multiple</v-icon>
              </v-btn>
              <v-btn color="blue-grey" small class="mx-1 white--text"
              @click="$root.mm = true; $root.wp = false;  handleSelect('dash')" v-if="$root.wp" >
                Building Blocks
                <v-icon right dark>mdi-text-box-multiple</v-icon>
              </v-btn>
              <v-btn
                @click="addusr=true"
                small
                v-if="$root.mm || $root.wp"
                color="blue-grey"
                class="mx-1 white--text"
              >
                Add User
                <v-icon right dark>mdi-account</v-icon>
              </v-btn>
              <v-btn
                @click="logout"
                small
                v-if="$root.mm || $root.wp" 
                color="blue-grey"
                class="mx-1 white--text"
              >
                Log Out
                <v-icon right dark>logout</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense v-if="$root.opname != ''" class="mx-3" justify="start">
            <v-col cols="4">
              Welcome: {{ $root.opname }} Date: {{ curdte }}
            </v-col>
            <v-spacer />
          </v-row>
        </v-col>
      </v-row>
      <template v-slot:extension >
            <v-tabs
              v-model="$root.navmenu"
              align-with-title
            >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                v-if="$root.mm"
                @click="handleSelect('dash')"
                >Dashboard</v-tab
              >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                v-if="$root.mm"
                @click="handleSelect('mission')"
                >Mission and Vision</v-tab
              >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                v-if="$root.mm"
                @click="handleSelect('endgame')"
                >End Game Analysis</v-tab
              >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                v-if="$root.mm"
                @click="handleSelect('marketing')"
                >Marketing Plan</v-tab
              >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                v-if="$root.mm"
                @click="handleSelect('5yp')"
                >Five Year Plan</v-tab
              >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                v-if="$root.mm"
                @click="handleSelect('swot')"
                >SWOT Analysis</v-tab
              >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                v-if="$root.mm"
                @click="handleSelect('12month')"
                >Next 12 Months</v-tab
              >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                v-if="$root.mm"
                @click="handleSelect('3month')"
                >Next 3 Months</v-tab
              >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                v-if="$root.mm"
                @click="handleSelect('busrep')"
                >Business Report</v-tab
              >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                @click="handleSelect('branding')"
                v-if="$root.wp"
                >Branding Secrets</v-tab
              >
              <v-tab
                class="subtitle-2 font-weight-light white--text"
                v-if="$root.wp"
                @click="handleSelect('marketing')"
                >2 Page Marketing Plan</v-tab
              >
              <v-spacer/>
              <v-tab
                v-if="$root.mm"
                class="subtitle-2 font-weight-light white--text"
                @click="handleSelect('todo')"
                >Ideas and Actions</v-tab
              >
            </v-tabs>
      </template>
    </v-app-bar>
    <v-row>
      <v-col cols="12">

            <v-sheet id="sheet1" class="overflow-y-auto pa-0">
              <router-view :key="$route.fullPath" />
            </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios").default;
var moment = require("moment");
export default {
  data() {
    return {
      name: "MainMenu",
      on: true,
      attrs: true,
      repkey: 1,
      currentpage: 0,
      usercode: "",
      password: "",
      helptext: "",
      helptextedit: "",
      curdte: moment().format("D/M/Y"),
      busover: true,
      tab: null,
      prevpage: 0,
      nextpage: 0,
    };
  },
   mounted: function() {
      this.handleSelect("logon");
  },

  methods: {
    SaveHelp() {
      let self = this;
      axios
        .get(self.$root.classes + "/savehelp.php", {
          params: {
            uid: this.$root.uid,
            id: this.$root.scrnname,
            helptext: this.helptextedit,
          },
        })
        .then(function(response) {
          if (response.data != "Success") {
            alert(response.data);
          }
        });
    },
    getHelp() {
      let self = this;
      axios
        .get(self.$root.classes + "/gethelp.php", {
          params: {
            uid: this.$root.uid,
            id: this.$root.scrnname,
          },
        })
        .then(function(response) {
          var hptext = response.data.helptext;
          //self.helptext = hptext;
          self.helptext = hptext.replace(/\n/g, "<p/>");
          self.helptextedit = hptext;
        });
    },
    logout() {
      this.$root.opcode = "";
      this.$root.coni = "";
      this.$root.coname = "";
      this.$root.scrnname = "";
      this.$root.navmenu = 0;
      this.handleSelect("logon");
    },
    handleSelect(key) {
      // switch (key) {
      //   case "prodsch":
      this.$router
        .push({
          name: key,
          params: { opcode: this.$root.opcode, uid: this.$root.uid },
        })
        .catch((e) => {
          if (e.name != "NavigationDuplicated") {
            // But print any other errors to the console
            console.error();
          }
        });
      this.tab = 0;
    },

  },
};
</script>
