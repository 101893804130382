<template>
  <v-app>
    <v-main>
      <v-card tile class="pa-0">
        <v-sheet id="sheet1" class="overflow-y-auto pa-0">
          <mainmenu />
        </v-sheet>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import mainmenu from "./components/mainmenu.vue";

export default {
  name: "vue",
  components: { mainmenu },

  data() {
    return {
      web: 1,
    };
  },
};
</script>
